import { AUTOCOMPLETE_GAMES_ID, Hint, MOST_SEARCHED_ID } from './searchTypes';
import { FetchPayload, doFetch } from 'features/api/thunkUtils';
import { Icon, IconsEnum } from '../Icons';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { handleSearch, isCategory } from './utils/utils';
import { useAutocompleteGame, useMostSearched } from './searchHooks';

import { CardType } from '../carousels/components/conditionalCard/ConditionalCard';
import { Category } from 'types/categoryEnum';
import { ConditionalCard } from '../carousels/components/conditionalCard';
import { SEARCH_DELAY } from 'utility/constant';
import { SearchInputText } from './searchInputText';
import { SearchResponseApi } from '../../lib/api/searchResponseApi';
import { SkeletonArea } from 'components/skeletonArea';
import { SlotCardType } from '../cards/slotCard';
import { resetHints } from './searchSlice';
import { sportApi } from 'features/api/sportApiSlice';
import styles from './SearchBar.module.scss';
import { useAppDispatch } from 'lib/centralStore';
import { useBlockScroll } from 'hooks/useBlockScroll';
import { useDebounce } from 'hooks';
import { useGetLabelByKey } from 'hooks/useLingUI';
import useOutsideAlerter from 'hooks/useClickOutside';
import { useRouter } from 'next/router';
import { useSearchParams } from 'hooks/useSearchParams';
import useWindowSize from 'hooks/useWindowSize';

const ElementRow = ({ game, searchedLabel, category }: { searchedLabel?: string; game: Hint; category: Category }) => {
  const router = useRouter();
  const isSearchedElementCategory = isCategory(game);

  return (
    <li
      onClick={() =>
        handleSearch({
          query: searchedLabel!,
          router,
          category,
          selectedSearch: game,
        })
      }
      key={isSearchedElementCategory ? game.name : game.id}
      className={styles.searchedItem}
    >
      <p>
        <label>{isSearchedElementCategory ? game.name : game.title}</label>
        <label>{isSearchedElementCategory ? game.name : game.category}</label>
      </p>
    </li>
  );
};

export type SearchBarProps = {
  title: string;
  category: Category;
  isMenuOpened: boolean;
  inputPlaceholder?: string;
  choseFromSnaiSlot?: Array<SlotCardType> | null;
  handleCloseMenu: (state: boolean) => void;
};

const MostSearched = ({
  isSplit,
  category,
  choseFromSnai,
}: {
  isSplit?: boolean;
  category: Category;
  choseFromSnai?: Array<SlotCardType> | null;
}) => {
  let cardType: CardType;
  const dispatch = useAppDispatch();
  const { isLoading, hints } = useMostSearched();
  const mostSearchedLabel = useGetLabelByKey('most-searched');
  const isSearchBarGameTitleHidden = (choseFromSnai ?? [])?.length > 1;

  useEffect(() => {
    dispatch(
      doFetch({
        uid: MOST_SEARCHED_ID,
        query: sportApi.endpoints.getMostSearched,
        category,
        paramAsObject: true,
      })
    );
  }, [category, dispatch]);

  if (category !== Category.Sport) {
    cardType = category === Category.CardGame ? 'giochidicarte' : category;
  }

  return (
    <section className={[styles.sectionList, isSplit ? styles.split : ''].join(' ')}>
      <div>
        <label className={styles.mostSearchedLabel}>{mostSearchedLabel}</label>
        {isLoading ? (
          <SkeletonGameText rows={5} />
        ) : (
          <ul>
            {hints?.map((hint, index) => (
              <ElementRow game={hint} key={`most-searched-${index}`} category={category} />
            ))}
          </ul>
        )}
      </div>
      {!!choseFromSnai?.length && category !== Category.Sport && (
        <div className={styles.chosenFromSnaiContainer}>
          <span className={styles.chosenFromSnai}>Scelto da snai</span>
          <div className={styles.cardsContainer}>
            {choseFromSnai.map((choseFromSnai, index) => {
              choseFromSnai.isHidden = isSearchBarGameTitleHidden;
              return (
                <ConditionalCard
                  type={cardType!}
                  item={choseFromSnai}
                  key={`choseFromSnaiCard-${index}-${choseFromSnai.slug}`}
                />
              );
            })}
          </div>
        </div>
      )}
    </section>
  );
};

const SearchedElement = ({
  games: { hints },
  category: category,
  searchedLabel,
}: {
  games: SearchResponseApi;
  category: Category;
  searchedLabel: string;
}) => {
  return (
    <ul className={[styles.sectionList, styles.searched].join(' ')}>
      {hints?.map((game, index) => {
        return <ElementRow game={game} searchedLabel={searchedLabel} key={`$searched-${index}`} category={category} />;
      })}
    </ul>
  );
};

const SkeletonGameText = ({ rows }: { rows: number }) => {
  return (
    <div className={styles.skeletonContainer}>
      {new Array(rows).fill('').map((_, index) => (
        <SkeletonArea
          height="2"
          key={`skeleton_${index}`}
          style={{ width: `${index % 2 ? index * 1.6 * 9 : index + 1 * 10}rem` }}
          delay={8}
        />
      ))}
    </div>
  );
};

export const SearchBar = ({
  title,
  category,
  isMenuOpened,
  inputPlaceholder,
  choseFromSnaiSlot,
  handleCloseMenu,
}: SearchBarProps) => {
  const MIN_CHAR = 2;
  const router = useRouter();
  const dispatch = useAppDispatch();
  const { isLoading, hints } = useAutocompleteGame();

  const valueFromQuery = useSearchParams<string>('q');
  const [searchValue, setSearchValue] = useState<string>(valueFromQuery ?? '');
  const { debouncedValue: debouncedSearchedText, isDebouncing } = useDebounce(searchValue, SEARCH_DELAY);

  const closeLabel = useGetLabelByKey('chiudi');
  const resetResearchLabel = useGetLabelByKey('cancella');
  const researchLabel = useGetLabelByKey('search');
  useBlockScroll(isMenuOpened);
  const { isMobile } = useWindowSize();

  const closeMenu = useCallback((): void => {
    handleCloseMenu(false);
    if (
      document?.activeElement instanceof HTMLElement &&
      document.activeElement.id !== 'react-select-custom-select-input'
    ) {
      document.activeElement.blur();
    }
  }, [handleCloseMenu]);

  const doReset = () => setSearchValue('');

  const doClose = useCallback(() => {
    doReset();
    closeMenu();
  }, [closeMenu]);

  useEffect(() => {
    doClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.asPath]);

  /**
   * Effect to reset hint when empty search
   */
  useEffect(() => {
    if (searchValue.length <= 1) {
      dispatch(resetHints());
    }
  }, [dispatch, searchValue]);

  const doSearch = useCallback(
    (searchText: string) => {
      if (searchText.length < 1) {
        const payload: FetchPayload<{}> = { uid: AUTOCOMPLETE_GAMES_ID, data: {} };
        dispatch({ type: doFetch.fulfilled.type, payload });
      } else {
        if (category !== Category.Sport) {
          dispatch(
            doFetch({
              uid: AUTOCOMPLETE_GAMES_ID,
              query: sportApi.endpoints.getAutocompleteGame,
              category,
              searchText,
              paramAsObject: true,
            })
          );
        }
      }
    },
    [category, dispatch]
  );

  useEffect(() => {
    // EFFECT TO RUN QUERY ON DEBOUNCE VALUE
    if (debouncedSearchedText.length > MIN_CHAR) {
      doSearch(debouncedSearchedText);
    }
  }, [doSearch, debouncedSearchedText, dispatch]);

  const searchRef = useRef<HTMLDivElement>(null);
  useOutsideAlerter({
    ref: searchRef,
    onClickOutside: closeMenu,
  });

  const onEnterKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (['Enter'].includes(e.key)) {
      handleSearch({ query: searchValue, router, category });
    }
  };

  const renderContent = useCallback(() => {
    if (searchValue.length > MIN_CHAR && (isDebouncing || isLoading)) {
      return <SkeletonGameText rows={8} />;
    }
    if (!!hints?.length)
      return <SearchedElement games={{ hints }} searchedLabel={searchValue ?? ''} category={category} />;
    if (category !== Category.Sport)
      return <MostSearched isSplit choseFromSnai={choseFromSnaiSlot} category={category!} />;
    return null;
  }, [category, choseFromSnaiSlot, hints, isDebouncing, isLoading, searchValue]);

  return (
    <div className={`${styles.wrapper} ${isMenuOpened && styles.wrapperOpened}`}>
      <div className={`${isMenuOpened ? styles.opened : styles.closed}`} ref={searchRef}>
        <h4 className={styles.headingTitle}>{title}</h4>
        <SearchInputText
          isMenuOpened={isMenuOpened}
          placeholder={inputPlaceholder}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          onKeyDown={onEnterKeyDown}
          leadingElement={<Icon iconId={IconsEnum.SEARCH} className={styles.iconSize} />}
          trailingElement={
            searchValue ? (
              <div className={styles.btnContainer}>
                <button type="button" className={styles.resetResearch} onClick={doReset}>
                  {resetResearchLabel}
                </button>
                {!isMobile && (
                  <button
                    type="button"
                    className={styles.researchButton}
                    onClick={() => handleSearch({ query: searchValue, router, category })}
                  >
                    {researchLabel}
                  </button>
                )}
              </div>
            ) : undefined
          }
        />
        <button type="button" className={styles.sectionActionable} onClick={doClose}>
          <Icon iconId={IconsEnum.CLOSE} className={styles.iconSize} />
          <span suppressHydrationWarning={true}>{closeLabel}</span>
        </button>
        {renderContent()}
      </div>
    </div>
  );
};
